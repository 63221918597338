import {
  HttpRequest,
  HttpEvent,
  HttpErrorResponse,
  HttpHandlerFn,
} from '@angular/common/http';
import {inject} from '@angular/core';
import {MessageService} from 'primeng/api';
import {catchError, Observable, tap, throwError} from 'rxjs';
import {AuthService} from "@core/services/api/auth.service";

export const responseInterceptor = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  const _AUTHSERVICE = inject(AuthService);
  const _MESSAGESERVICE = inject(MessageService);

    return next(req).pipe(
      tap((event: HttpEvent<unknown>) => {
        return true;
      }),
      catchError((httpErrorResponse: HttpErrorResponse) => {
        if ((httpErrorResponse.status === 401)) {
          _AUTHSERVICE.logout();

        }
        _MESSAGESERVICE.add({
          key: httpErrorResponse.status !== 401? 'toast' : '',
          detail:
            httpErrorResponse.status !== 401? httpErrorResponse.error.message : 'Error desconocido',
          severity: 'warn',
          summary: 'Error',
        });

        return throwError(() => httpErrorResponse);
      })
    );
};
