import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DataUsuarioService {
  constructor() {}

  set empresaCodigo(codigo: number) {
    localStorage.setItem('empresa_codigo', codigo ? codigo.toString() : '');
  }

  get empresaCodigo(): number {
    return Number(localStorage.getItem('empresa_codigo'));
  }

  set almacenId(value: number) {
    localStorage.setItem('almacenId', value.toString());
  }

  get almacenId() {
    return Number(localStorage.getItem('almacenId'));
  }

  set conteoId(value: number) {
    localStorage.setItem('conteoId', value.toString());
  }

  get conteoId() {
    return Number(localStorage.getItem('conteoId'));
  }
}
